import React from 'react';
import { graphql } from 'gatsby';

import Projects from '../components/pages/projects';
export const query = graphql`
	query {
		allContentfulProjects(sort: { fields: publishedDate, order: DESC }) {
			edges {
				node {
					title
					slug
					publishedDate(formatString: "MMMM Do, YYYY")
					projectImage {
						resize(width: 220, height: 400) {
							src
						}
					}
				}
			}
		}
	}
`;

export default function IndexPage({ data }) {
	return <Projects data={data} />;
}
