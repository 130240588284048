import React from 'react';
import cardStyles from './card.module.scss';
import Tilt from 'react-parallax-tilt';
import { navigate } from 'gatsby';

const Card = ({ img, text, to }) => {
	const style = { backgroundImage: 'url(' + img + ')' };
	const clickHandler = (e) => {
		navigate(to);
	};

	return (
		<div onClick={clickHandler} style={style} className={cardStyles.card}>
			<h1 className={cardStyles.innerElement}>{text}</h1>
		</div>
	);
};

export default Card;
