import React from 'react';
import Card from '../card';
import projectStyles from './projects.module.scss';

const Projects = ({ data }) => {
	return (
		<div className={projectStyles.container}>
			{data.allContentfulProjects.edges.map((edge) => (
				<Card
					to={`/projects/${edge.node.slug}`}
					key={edge.node.title}
					text={edge.node.title}
					img={edge.node.projectImage.resize.src}
				/>
			))}
		</div>
	);
};

export default Projects;
